<template>
  <div style="height: 100%;">

    <nav class="level mx-5 mt-5 mb-4">
      <div class="level-left">
        <a @click="$router.go(-1)" class="level-item">
          <b-icon type="is-grey-darker" icon="arrow-left"></b-icon>
        </a>
        <div class="level-item">
          <p v-if="mapitem" class="subtitle is-5">
            <map-item-namechange-component
                :curname="mapitem.name"
                :reset="loading"
                @saveMapItemName="onSaveMapItemName(mapitem.id, $event)"
            ></map-item-namechange-component>
          </p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-tag :type="mapitem.status.color"
                 close-type='is-warning'
                 close-icon-type='is-dark'
                 attached
                 :close-icon='mapitem.status.icon'>
            {{ mapitem.status.name }}
          </b-tag>
        </div>
      </div>
    </nav>
    <hr class="mb-1">

    <b-tabs class="px-5 pb-2" style="height: 100%">
      <b-tab-item label="Details">
        <form @submit.prevent="updateDevice">

          <b-field label="Short Name">
            <b-input v-model="mapitem.name"></b-input>
          </b-field>
          <b-field label="Status">
            <b-select v-model="mapitem.status.id" expanded>
              <option :value="1">Draft</option>
              <option :value="2">Quoted</option>
              <option :value="3">Planned</option>
              <option :value="4">Placed</option>
              <option :value="5">Tested</option>
              <option :value="6">Error</option>
            </b-select>
          </b-field>
          <b-field label="Item Category">
            <treeselect v-model="mapitem.category.id"
                        :multiple="false"
                        :show-count="true"
                        :options="categories"/>
          </b-field>
          <b-field label="Hierarchy">
            <nav class="breadcrumb has-arrow-separator">
              <ul>
                <li v-for="(title,index) in mapitem.breadcrumb" :key="index"><a>{{ title }}</a></li>
                <li class="is-active"><a>{{ mapitem.name }}</a></li>
              </ul>
            </nav>

          </b-field>
          <b-field label="Device location">
            <b-input v-model="mapitem.location"></b-input>
          </b-field>
          <b-field label="Level">
            <b-numberinput v-model="mapitem.level"></b-numberinput>
          </b-field>

          <b-field label="Parent Device (uplink)" expanded>
            <p class="control">
              <span v-if="mapitem.parent" class="button is-success is-static">{{ mapitem.parent.name }}</span>
              <span v-else class="button is-static">not linked</span>
            </p>
            <p class="control">
              <b-button expanded @click="selectFeature" icon-left="mapbox">select...</b-button>
            </p>
          </b-field>

          <hr>
          <div class="buttons">
            <b-button type="is-info" native-type="submit" expanded>Update Device</b-button>
          </div>
        </form>
      </b-tab-item>

      <b-tab-item label="Activity">
        <CommentListComponent type="MapItem" :id="mapitem.id"></CommentListComponent>
      </b-tab-item>


    </b-tabs>

  </div>
</template>
<script>

import CardComponent from '@/components/CardComponent'
import MapItemNamechangeComponent from "@/components/deplo/components/MapItemNamechangeComponent";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import axios from 'axios'
import CommentListComponent from "@/components/deplo/components/CommentListComponent";

export default {
  name: 'MapItemDetailSidebar',
  props: {
    selected: {
      default: null
    },
    visible: {
      default: true
    },
    isCreating: {
      default: true
    }
  },
  components: {
    CommentListComponent,
    CardComponent,
    Treeselect,
    MapItemNamechangeComponent
  },
  data() {
    return {
      loading: false,
      select_toast: null,
    }
  },
  computed: {
    mapitem() {
      return this.$store.getters["deplo/getMapItem"](this.$route.params.id)
    },

    categories() {
      return this.$store.state.deplo.categories
    },

    store_selected() {
      return this.$store.state.deplo.selectedFeatures
    }

  },
  beforeMount() {
    //this.fetchCategories()
    this.$store.dispatch("deplo/fetchMapItem", this.$route.params.id)
  },
  watch: {
    '$route'(to, from) {
      this.$store.dispatch("deplo/fetchMapItem", this.$route.params.id)
    },
    store_selected: function () {
      if (this.select_toast) { // == waiting for selection
        console.log("waiting and device found")
        this.mapitem.parent = {
          id: this.store_selected[0].id,
          name: this.store_selected[0].properties.name
        }
        this.select_toast.close()
        this.$store.commit('deplo/setWaitingForSelection', false)
        this.updateDevice()

      }
    },
    selected: function () {
      console.log("selected function: ", this.selected)
      if (this.selected.features.length > 1) {
        this.visible = false
        this.device = {}
      } else if (this.selected.features.length === 0) {
        this.visible = false
        this.device = {}
      } else if (this.isCreating) {
        console.log('tis een nieuwe')
        this.visible = true
      } else {
        this.fetchDevice(this.selected)
        this.visible = true
      }
    }
  },
  methods: {

    async updateDevice() {
      let updated_item = this.mapitem
      updated_item.point = JSON.stringify(this.mapitem.point)
      updated_item.category_id = this.mapitem.category.id
      updated_item.status_id = this.mapitem.status.id
      if (this.mapitem.parent) {
        updated_item.parent_id = this.mapitem.parent.id
      }

      this.$store.dispatch('deplo/updateMapItem', updated_item).then(response => {
        this.$buefy.toast.open({
          message: 'Item name updated',
          type: 'is-success'
        })
      })
    },

    selectFeature() {
      this.select_toast = this.$buefy.toast.open({
        message: 'Select a feature on the map as parent...',
        indefinite: true,
      })
      this.$store.commit('deplo/setWaitingForSelection', true)

    }
  }
}
</script>

<style>

.vue-treeselect__control {
  height: 40px;
}

.b-tabs .tab-content {
  padding: 0;
  padding-top: 1rem;
  margin-left: 0;
}

</style>
