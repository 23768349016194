<template>
  <div>
    <nav class="level mx-5 mt-5 mb-4">
      <div class="level-left">
        <div class="level-item">
          <p class="subtitle is-5">
            <strong>{{ project_name }}</strong>
          </p>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button size="is-small" @click="$router.push({name: 'deplo.items', params: {project_id: project_id}})">View table
          </b-button>
        </div>
      </div>
    </nav>

  </div>
</template>
<script>

import axios from 'axios'

export default {
  name: 'MapItemOverviewSidebar',
  props: {
    selected: {
      default: null
    },
    visible: {
      default: true
    },
    isCreating: {
      default: true
    }
  },
  components: {},
  data() {
    return {
      device: {},
      categories: []
    }
  },
   computed: {
      project_name() {
        return this.$store.state.deplo.currentProjectName
      },
     project_id() {
       return this.$store.state.deplo.currentProject
     }
   },
  watch: {
    selected: function () {
      console.log('Changed')
      if (this.selected.features.length > 1) {
        this.visible = false
        this.device = {}
      } else if (this.selected.features.length === 0) {
        this.visible = false
        this.device = {}
      } else if (this.isCreating) {
        console.log('tis een nieuwe')
        this.visible = true
      } else {
        this.fetchDevice(this.selected)
        this.visible = true
      }
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
