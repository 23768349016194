<template>
  <div class="has-height-medium" style="height: 100%">
      <div ref="commentContent" class="media-list" style="height: calc(90vh - 350px);
      position: relative;">
        <transition-group name="list" tag="p">
        <article v-for="comment in comments" :key="comment.id" class="media mb-0">
          <figure class="media-left">
            <p class="image is-32x32">
              <user-avatar :email="comment.user.email"/>

            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <strong>{{ comment.user.name }}</strong> <small>{{ comment.timestamp | moment("from", "now") }}</small>
                <b-image v-if="comment.attachment"
                         :src="comment.attachment"
                         @click.native="imageModal(comment.attachment)"
                         class="mb-0 mt-2 ml-0"
                >
                  <template #placeholder>
                    <b-skeleton
                        class="skeleton-placeholder"
                        height="200px"
                    ></b-skeleton>
                  </template>
                </b-image>
                <template v-else>
                  <br>
                </template>
                {{ comment.body }}
              </p>
            </div>
          </div>
          <div v-if="comment.user.id === user.id" class="media-right">
            <b-icon @click.native="deleteComment(comment.id)" style="cursor: pointer" type="is-grey-light" size="is-small" icon="close"></b-icon>
          </div>

        </article>
        </transition-group>
        <template v-if="comments">
          <article class="has-text-centered" v-if="comments.length === 0">
            <small>There are no comments yet.</small>
          </article>
        </template>

      </div>

    <hr>

    <form @submit.prevent="addComment">

      <file-pond
          name="file"
          ref="pond"
          label-idle="Drop image here to upload..."
          credits="false"
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="serverSettings"
          @addfilestart="loading = true"
          @processfile="addComment"
          @processfiles="loading = false"
      />
      <b-field>
        <b-input v-model="comment_text" expanded placeholder="Write a comment..."></b-input>
        <p class="control">
          <b-button :loading="uploading" :disabled="loading" native-type="submit" type="is-info" label="Send"/>
        </p>
      </b-field>
    </form>


  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

import PerfectScrollbar from 'perfect-scrollbar'
import UserAvatar from "@/components/UserAvatar";


// Create component
const FilePond = vueFilePond(

);

export default {
  name: "CommentListComponent",
  components: {
    FilePond,
    UserAvatar
  },
  props: {
    id: {
      type: Number
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      comment_text: null,
      myFiles: null,
      loading: false,
      uploading: false,
      serverSettings: {
        process: {
          url: '/api/process/',
          withCredentials: true,
          headers: {
            'x-xsrf-token': this.$cookies.get("XSRF-TOKEN")
          },
        },
        revert: {
          url: '/api/process/',
          withCredentials: true,
          headers: {
            'x-xsrf-token': this.$cookies.get("XSRF-TOKEN")
          },
        },
      },
      settings: {
        maxScrollbarLength: 60
      },
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    comments() {
      //return this.$store.getters["deplo/commentsForMapItem"](this.id)
      return this.$store.state.deplo.mapitem_comments[this.id]
    }
  },
  mounted() {
    this.ps = new PerfectScrollbar(this.$refs.commentContent)
    this.$emit('ps-ready', this.ps)
    this.$refs.commentContent.scrollTop = this.$refs.commentContent.scrollHeight;
  },
  methods: {
    addComment() {
      let attachments = this.$refs.pond.getFiles().map(e => e.serverId)
      this.uploading = true;
      this.$store.dispatch("deplo/createComment", {
        body: this.comment_text,
        commentable_id: this.id,
        commentable_type: this.type,
        attachment: attachments ? attachments[0] : null // TODO Currently only one photo max
      }).then(resp => {
        this.uploading = false
        this.comment_text = null
        this.$refs.pond.removeFile(attachments)
        this.$refs.commentContent.scrollTop = this.$refs.commentContent.scrollHeight;
        console.log(this.comments)
      }).catch(err => {
        this.uploading = false
        console.log(err)
        this.$buefy.toast.open({
          message: `Something went wrong.`,
          type: 'is-danger'
        })
      })
    },

    deleteComment(id) {
      this.$store.dispatch("deplo/deleteComment", id).then(resp => {
        this.$buefy.toast.open({
          message: `Comment deleted`,
          type: 'is-success',
          queue: false,
        })
      }).catch(err => {
        console.log(err)
        this.$buefy.toast.open({
          message: `Something went wrong: ${err}`,
          type: 'is-danger'
        })
      })
    },

    imageModal(src) {
      console.log("ImageModal")
      const h = this.$createElement
      const vnode = h('p', { class: "image" }, [
        h('img', { attrs: { src: src }})
      ])
      this.$buefy.modal.open({
        content: [ vnode ]
      })
    },

  }
}
</script>

<style>

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 0.8s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>